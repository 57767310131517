import { BLOCKED_EMAIL_DOMAINS } from "@constants";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import moment from "moment";
import { MouseEvent, lazy, useRef } from "react";

declare global {
  interface Window {
    __skipJsPageTitleGeneration: boolean;
    analytics: any;
    hj: any;
  }
  const analytics: any;
  const hj: any;
}

export const isAsyncFunction = (fn) => {
  if (!fn) {
    return false;
  }

  if (fn.constructor.name === "AsyncFunction") {
    return true;
  }
  return false;
};

export const getUserInfo = (currentUser) => {
  if (!currentUser) return {};

  const userInfo = Object.assign({}, currentUser);
  const profileInfo = Object.assign({}, currentUser.profile_info);

  profileInfo.profile_role = profileInfo?.role;
  profileInfo.profile_verified = profileInfo?.verified;

  delete userInfo.profile_info;
  delete userInfo.image;
  delete userInfo.verification_code;

  delete profileInfo.id;
  delete profileInfo.user_id;
  delete profileInfo.updated_at;
  delete profileInfo.created_at;
  delete profileInfo.role;
  delete profileInfo.followed_companies_info;
  delete profileInfo.followed_research_interests;
  delete profileInfo.followed_rfp_programs;
  delete profileInfo.followed_request_ids;
  delete profileInfo.proposals;
  delete profileInfo.verified;

  const userProperties = {
    ...userInfo,
    ...profileInfo,
  };

  return userProperties;
};

/**
 * Helper singleton that does nothing for methods that require a function input.
 */
export const emptyFunction = () => {};

export const identify = (currentUser) => {
  window?.analytics?.identify(currentUser.id, getUserInfo(currentUser));
  if (["development", "staging"].some((s) => gon?.global?.env?.includes(s))) {
    console.debug(`Analytics: identify() %o`, getUserInfo(currentUser));
  }
};

export const page = () => {
  window?.analytics?.page();
  if (["development", "staging"].some((s) => gon?.global?.env?.includes(s))) {
    console.debug(`Analytics: page()`);
  }
};

/**
 * TODO: Replace eventName with SegmentEventName once all callers have been migrated.
 */
export const track = (eventName: string, info: any = {}) => {
  if (eventName === SegmentEventName.View || eventName === SegmentEventName.Click) {
    info.current_url = window.location.href;
  }

  window?.analytics?.track(eventName, info);
  if (["development", "staging"].some((s) => gon?.global?.env?.includes(s))) {
    console.debug(`Analytics: track(%s) %o`, eventName, info);
  }
};

export const waypoint = (eventName: string) => {
  window?.hj?.("event", eventName);
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const setSearchParam = (history, name, value, push = false) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(name, value);

  if (push) {
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  } else {
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }
};

export const deleteSearchParam = (history, name) => {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has(name)) {
    searchParams.delete(name);
    history.replace({ search: searchParams.toString() });
  }
};

export const usedPersonalEmail = (email) => {
  for (const domain of BLOCKED_EMAIL_DOMAINS) {
    if (email.endsWith(`@${domain}`)) {
      return true;
    }
  }
  return false;
};

// Takes a DateTime in UTC and returns the remaining full days from the user's time zone.
export const daysUntilLocalDate = (date) => {
  if (!date?.includes("Z")) return;

  const localDate = moment(date);
  const daysUntilDate = Math.ceil(localDate.diff(Date.now(), "days", true));

  return daysUntilDate;
};

export const debounce = (functionToDebounce, delay = 300) => {
  let timer;

  const debounceRef = useRef((...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => functionToDebounce(...args), delay);
  });

  return debounceRef.current;
};

/**
 * @deprecated - Move to Rails https://www.notion.so/halodotscience/Move-page-title-setting-to-Rails-side-9c6592da85e644dca8fea1fb413c03c1?pvs=4
 */
export const setPageTitle = (pathname: string): void => {
  if (window.__skipJsPageTitleGeneration) {
    return;
  }

  const format = (param: string, ignoreNum = false): string =>
    param
      .replace(/-/g, " ")
      .split(" ")
      .flatMap((word: string): string[] => {
        if (parseInt(word) && ignoreNum) return [];
        return [word.charAt(0).toUpperCase() + word.slice(1)];
      })
      .join(" ");

  const path = pathname.split(/[/,?]+/);
  let title = "Funding Opportunities with Industry";

  if (path[1] === "company" && path[2]) {
    title = `Funding Opportunities with ${format(path[2], false)}`;
  }
  if (path[1] === "research" && path[2] && !path[3]) {
    title = `Funding Opportunities in ${format(path[2])}`;
  }
  if (path[1] === "research" && path[2] && path[3]) {
    // Rfp pages set their own title
    return;
  }
  if (path[1] === "profile" && path[2]) {
    const formattedName = format(path[2].slice(0, path[2].lastIndexOf("-")));
    title = `${formattedName}'s profile`;
  }

  document.title = title;
};

// Fix for ChunkLoadError
// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb
export const lazyWithRetry = (componentimport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage?.getItem("page-has-been-force-refreshed") ?? "false"
    );

    try {
      const component = await componentimport();

      window.localStorage?.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage?.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export const stopPropagation = (event: MouseEvent) => event.stopPropagation();

export const compactStringArray = (array: string[]): string[] => {
  if (typeof array !== "object") return [];

  const compactedArray = array.filter((item) => typeof item === "string" && item.trim().length > 0);

  return compactedArray;
};

export const getArrayFromRubyRange = (range: string): [number, number] => {
  if (!range?.includes("...")) throw new Error("Invalid Ruby range");

  return [Number(range[0]), Number(range.split("...")[1]) - 1];
};

export const getRubyRangeFromArray = (array: [number, number]): string => {
  return `${array[0]}...${array[1] + 1}`;
};
