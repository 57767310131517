import { useUniversityOptions } from "src/views/Onboarding/Hooks";

import Fuse from "fuse.js";
import { DropdownOption } from "./DropdownOption";
import MultiSelectDropdown from "./MultiSelectDropdown";

interface Props {
  value: DropdownOption | null;
  onChange: (newValue: DropdownOption | null) => void;
}

export default function UniversityDropdown({ value, onChange }: Props) {
  const universityOptions = useUniversityOptions();

  const searchUniversities = async (query: string): Promise<DropdownOption[]> => {
    const fuse = new Fuse(universityOptions, {
      keys: ["value"],
    });
    const result = fuse.search(query);
    const values = result.map((r: any) => r.item).slice(0, 500);
    return values;
  };

  return (
    <MultiSelectDropdown
      placeholder="Search for your research institution"
      value={value}
      onChange={onChange}
      isMulti={false}
      maxMenuHeight="250px"
      menuPlacement="bottom"
      searchFn={searchUniversities}
      closeOnMenuSelect
    />
  );
}
