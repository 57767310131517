import MultiSelectDropdown from "@components/library/Dropdowns/MultiSelectDropdown";
import { COLORS, FEATURE_FLAGS, FONTS } from "@constants";
import {
  createKeyword,
  getAreasOfExpertise,
  getDisciplines,
  getKeywordSuggestions,
} from "@requests/keywords";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import debounce from "debounce-promise";
import Fuse from "fuse.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ActionMeta } from "react-select";
import styled from "styled-components";
import type { DropdownOption, DropdownOptionOrGroup } from "../DropdownOption";
import KeywordsDropdownCreateLabel from "./KeywordsDropdownCreateLabel";
import KeywordsDropdownOption from "./KeywordsDropdownOption";

export type KeywordType = "disciplines" | "areas_of_expertise";

type SuggestedDropdownOption = DropdownOption & { suggested?: boolean };

const KEYWORD_TYPE_TO_CLASS = {
  disciplines: "Discipline",
  areas_of_expertise: "AreaOfExpertise",
} as const;

const ANALYTICS_UI_COMPONENT = "keywords_dropdown";
enum SuggestionType {
  INPUT_SUGGESTION = "input_suggestion", // AI-suggested keywords based on input
  VALUE_SUGGESTION = "value_suggestion", // AI-suggested keywords based on selected values
  CREATED = "created", // User-created keyword
  NONE = "none", // From normal dropdown options
}

interface Props {
  type: KeywordType;
  currentUserKeywords?: {
    disciplines?: string[];
    areas_of_expertise?: string[];
  };
  showSuggestions?: boolean;
  value: string[];
  onChange: (selection: string[]) => void;
  maxValues?: number;
  placeholder?: string;
  menuPlacement?: "top" | "auto" | "bottom";
  isPortal?: boolean;
  maxMenuHeight?: string;
  maxValueContainerHeight?: string;
  helpText?: string;
  errors?: { hasError: boolean; errorMessage: string }[];
}

const KeywordsDropdown = ({
  type,
  currentUserKeywords,
  showSuggestions = false,
  value,
  onChange,
  maxValues,
  placeholder,
  menuPlacement,
  isPortal = false,
  maxMenuHeight,
  maxValueContainerHeight = "unset",
  helpText,
  errors,
}: Props) => {
  const [allKeywords, setAllKeywords] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<DropdownOptionOrGroup[]>([]);
  const [options, setOptions] = useState<DropdownOptionOrGroup[]>([]);
  const [isInputSuggestionsLoading, setIsInputSuggestionsLoading] = useState(false);

  // Grab set of raw keywords
  useEffect(() => {
    (async () => {
      let keywords: string[] = [];
      switch (type) {
        case "disciplines":
          keywords = await getDisciplines();
          break;
        case "areas_of_expertise":
          keywords = await getAreasOfExpertise();
          break;
      }
      setAllKeywords(keywords);

      let _options: DropdownOptionOrGroup[] = [];
      const usedKeywords: string[] = [];
      if (currentUserKeywords?.disciplines?.length) {
        _options.push({
          label: "Your disciplines",
          options: currentUserKeywords.disciplines.map(getOptionFromValue) as DropdownOption[],
        });
        usedKeywords.push(...currentUserKeywords.disciplines);
      }
      if (currentUserKeywords?.areas_of_expertise?.length) {
        _options.push({
          label: "Your areas of expertise",
          options: currentUserKeywords.areas_of_expertise.map(
            getOptionFromValue
          ) as DropdownOption[],
        });
        usedKeywords.push(...currentUserKeywords.areas_of_expertise);
      }

      // Add normal keywords excluding extra groups
      if (usedKeywords.length) {
        _options.push({
          label: "All options",
          options: keywords
            .filter((keyword) => !usedKeywords.includes(keyword))
            .map(getOptionFromValue) as DropdownOption[],
        });
      } else {
        // Add options without group label if no extra groups were created
        _options = keywords.map(getOptionFromValue) as DropdownOption[];
      }
      setDefaultOptions(_options);
      setOptions(_options);
    })();
  }, []);

  // Suggestions
  const debouncedGetKeywordSuggestionsForValue = useCallback(
    debounce(getKeywordSuggestions, 500),
    []
  );
  const debouncedGetKeywordSuggestionsForInput = useCallback(
    debounce(getKeywordSuggestions, 500),
    []
  );
  const getSuggestionsForValue = async (values: string[]) => {
    if (values.length === 0 || !showSuggestions || !FEATURE_FLAGS.KEYWORD_SUGGESTIONS) {
      setSuggestions([]);
      return;
    }
    setSuggestions(
      await debouncedGetKeywordSuggestionsForValue(values, KEYWORD_TYPE_TO_CLASS[type])
    );
  };
  const getSuggestionsForInput = async (input: string): Promise<string[]> => {
    if (input.length === 0 || !showSuggestions || !FEATURE_FLAGS.KEYWORD_SUGGESTIONS) {
      return [];
    }
    return debouncedGetKeywordSuggestionsForInput([input], KEYWORD_TYPE_TO_CLASS[type]);
  };

  // Format options into groups
  const formatOptionsWithSuggestions = (keywords: string[], querySuggestions: string[]) => {
    const _options: DropdownOptionOrGroup[] = keywords.map(getOptionFromValue) as DropdownOption[];
    if (querySuggestions.length > 0) {
      _options.push({
        hasDivider: keywords.length > 0,
        iconName: "Magic",
        label: "Alternative terms",
        options: querySuggestions
          .filter((keyword) => !keywords.includes(keyword) && !value.includes(keyword))
          .map((v) => ({
            ...getOptionFromValue(v),
            suggested: true,
          })) as SuggestedDropdownOption[],
      });
    }
    return _options;
  };

  // Filtering - fuzzy match + input suggestions
  // We're manually controlling options to allow for async input suggestions
  const fuse = useMemo(() => new Fuse(allKeywords, { threshold: 0.4 }), [allKeywords]);
  const handleInputChange = (input: string): void => {
    if (input.length === 0) {
      setOptions(defaultOptions);
      return;
    }

    // Immediately populate fuzzy match filtered options
    const result = fuse.search(input);
    const keywords = result.map((r: any) => r.item).slice(0, 50);
    setOptions(formatOptionsWithSuggestions(keywords, []));

    if (keywords.length > 10 || !FEATURE_FLAGS.KEYWORD_SUGGESTIONS) return;

    // Fetch suggestions (async and debounced) and populate at bottom later
    if (keywords.length === 0) setIsInputSuggestionsLoading(true);
    getSuggestionsForInput(input).then((inputSuggestions: string[]) => {
      setOptions(formatOptionsWithSuggestions(keywords, inputSuggestions));
      setIsInputSuggestionsLoading(false);
    });
  };

  const testid = `${type}-dropdown`;

  return (
    <div data-testid={testid}>
      <MultiSelectDropdown
        isPortal={isPortal}
        maxMenuHeight={maxMenuHeight}
        maxValueContainerHeight={maxValueContainerHeight}
        maxValues={maxValues}
        menuPlacement={menuPlacement}
        components={{ Option: KeywordsDropdownOption }}
        formatCreateLabel={KeywordsDropdownCreateLabel}
        onChange={(e: SuggestedDropdownOption[], actionMeta?: ActionMeta<DropdownOption>) => {
          const newValues = e.map(getValueFromOption) as string[];
          getSuggestionsForValue(newValues);
          onChange(newValues);

          if (actionMeta?.action === "select-option") {
            track(SegmentEventName.Click, {
              ui_component: ANALYTICS_UI_COMPONENT,
              suggestion_type: e.at(-1)!.suggested ? "input_suggestion" : "none",
              keyword: e.at(-1)!.value,
            });
          }
        }}
        options={options}
        placeholder={placeholder}
        value={value.map((text) => ({ value: text, label: text }))}
        helpText={helpText}
        errors={errors}
        onInputChange={handleInputChange}
        filterOption={() => true} // Allow all options to pass filter since we're manually controlling them
        isLoading={isInputSuggestionsLoading}
        isCreatable={FEATURE_FLAGS.KEYWORD_SUGGESTIONS}
        onCreateOption={async (input: string) => {
          await createKeyword(input, KEYWORD_TYPE_TO_CLASS[type]);
          const newValues = [...value, input];
          getSuggestionsForValue(newValues);
          onChange(newValues);

          track(SegmentEventName.Click, {
            ui_component: ANALYTICS_UI_COMPONENT,
            suggestion_type: SuggestionType.CREATED,
            keyword: input,
          });
        }}
      />
      {showSuggestions &&
        (suggestions ?? []).length > 0 &&
        value.length > 0 &&
        value.length < maxValues! && (
          <Suggestions>
            Suggested:{" "}
            {suggestions.map((suggestion, index) => (
              <span key={`suggestion-${suggestion}`}>
                {index > 0 && ", "}
                <Suggestion
                  onClick={() => {
                    setSuggestions(suggestions.filter((s) => s !== suggestion));
                    onChange([...value, suggestion]);

                    track(SegmentEventName.Click, {
                      ui_component: ANALYTICS_UI_COMPONENT,
                      suggestion_type: SuggestionType.VALUE_SUGGESTION,
                      keyword: suggestion,
                    });
                  }}
                >
                  {suggestion}
                </Suggestion>
              </span>
            ))}
          </Suggestions>
        )}
    </div>
  );
};

export default KeywordsDropdown;

const Suggestions = styled.div`
  margin-top: 8px;
  color: ${COLORS.BLACK};
  ${FONTS.REGULAR_3};
`;

const Suggestion = styled.button`
  text-decoration: underline;
  cursor: pointer;
  color: ${COLORS.BLACK};
  border: none;
  background: none;
`;
